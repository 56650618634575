function redirect (url) {
    var ua        = navigator.userAgent.toLowerCase(),
    isIE      = ua.indexOf('msie') !== -1,
    version   = parseInt(ua.substr(4, 2), 10);

    // Internet Explorer 8 and lower
    if (isIE && version < 9) {
        var link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
    }

    // All other browsers can use the standard window.location.href (they don't lose HTTP_REFERER like IE8 & lower does)
    else { 
        window.location.href = url; 
    }
}

window.onload = function() {
    if (document.location.hash.length > 0) {
        var _hash = document.location.hash.slice(1, document.location.hash.length);
        if (_hash == 'login') {
            $('#modal-login').modal('show');
        } else if (_hash == 'restauracje') {
            $('.category-btn').eq(0).click();
        } else if ($('#' + _hash).hasClass('tab-cont')) {
            $('.tab-btn[href="' + _hash + '"]').click();
        }
    }
}

$(function() {
    'use strict';

    $('body').addClass('ready');

    $('.text-cont').each(function() {
        $(this).removeOrphans();
    });
    $('#header-menu').click(function(e) {
        e.preventDefault();

        if ($('body').hasClass('menu-active')) {
            $('body').removeClass('no-scroll');
            $('body').removeClass('menu-active');

        } else {
            $('body').addClass('no-scroll');
            $('body').addClass('menu-active');
            if ($('#rwd-checker').width() > 2) {
                $("body,html").animate({
                    scrollTop: 0
                }, 100);
            }
            $('html,body').click(function(e) {
                if ($(e.target).closest('#header-menu').length < 1) {
                    $('body').removeClass('menu-active');
                    $('body').removeClass('no-scroll');
                }
            });
        }
    });

    $('.mobile-sidebar select').change(function() {
        window.location = $(this).val();
    });

    if ($('#affix-section').length && !$('.page-home').length) {
        var AffixObj = function($container, $scroll, $offsetContainer) {
            this.container = $container,
            this.scroll = $scroll,
            this.affixPoint = $offsetContainer;
        }

        AffixObj.prototype.init = function() {
            var _el = this,
            _ofc = this.affixPoint,
            _co = this.container,
            _sc = this.scroll;

            var offsetCheck = function(){
                if(this.mcs.topPct === 100 && !$(this).hasClass('disabled-bottom') ){
                    $(this).addClass('disabled-bottom');
                } else if ( this.mcs.topPct === 0 && !$(this).hasClass('disabled-top') ){
                    $(this).addClass('disabled-top');
                } else if ( this.mcs.topPct > 0 && this.mcs.topPct < 100){            
                    if($(this).hasClass('disabled-top')){
                        $(this).removeClass('disabled-top')
                    } else if ( $(this).hasClass('disabled-bottom') ){
                        $(this).removeClass('disabled-bottom')
                    }
                }
            }

            var _scrollOptions = {
                scrollbarPosition: 'outside',
                axis: 'y',
                scrollButtons: { enable: true },
                mouseWheel: { preventDefault: true, scrollAmount: 200 },
                advanced: { updateOnContentResize: true },
                callbacks: {
                    onInit: offsetCheck,
                    whileScrolling: offsetCheck
                }
            };

            if( $('.page-restauracja').length ) {
                $(window).scrollTop(0);
                _co.affix({
                    offset: {
                        top: function() {
                            return _ofc.offset().top;
                        },
                        bottom: function() {
                            return $('footer').height() + $('.partners-section').height() + parseInt($('.main-container').css('padding-bottom'));
                        }
                    }
                }).on('affix.bs.affix', function() {
                    $('.main-section').height($('.main-section').height());
                    _sc.mCustomScrollbar(_scrollOptions);
                    _co.css({ 'left': _ofc.offset().left + parseInt(_ofc.css('padding-left')), 'width': _ofc.width() });
                    _el.calcHeights(true);
                }).on('affixed-top.bs.affix affixed-bottom.bs.affix', function() {
                    _co.css({ 'left': 'auto', 'width': 'auto' });
                    _sc.mCustomScrollbar("destroy");
                    $('.main-section').height('auto');
                    _el.calcHeights(false);
                });
            } else {
                $('.main-section').addClass('small-cols');
                _co.height($('.center-side').outerHeight());
                _sc.mCustomScrollbar(_scrollOptions);
            }
            $(window).scroll();
        }

        AffixObj.prototype.calcHeights = function(flag) {
            if (flag && !this.container.hasClass('calculated')) {
                this.container.addClass('calculated');
                if (this.container.height() > $(window).height()) {
                    this.container.css('height', '100%');
                    this.scroll.css('height', '100%');
                } else {
                    this.container.css('height', 'auto');
                    this.scroll.css('height', 'none');
                }
            } else if (!flag && this.container.hasClass('calculated')) {
                this.container.removeClass('calculated')
                this.container.css('height', 'auto');
                this.scroll.css('height', 'none');
            }
        }

        var _affixLeft = new AffixObj($('#affix-section'), $('.desktop-sidebar .category-scroll'), $('.left-side'));
        var _affixRight = new AffixObj($('.ad-container'), $('.ad-container .custom-scroll'), $('.right-side'));

        _affixLeft.init();
        _affixRight.init();

        var resizeTimer;
        $(window).on('resize', function(e) {
          clearTimeout(resizeTimer);
          resizeTimer = setTimeout(function() {
            $('body,html').animate({
                scrollTop: $('.desktop-sidebar').offset().top - 3
            }, 100);
        }, 250);
      });

    }

    $('#go-top').click(function(e) {
        e.preventDefault();
        $("body,html").animate({
            scrollTop: 0
        }, 100);
    });

    $('.my-account a, .nav-main a, .tab-toggle').click(function(e) {
        e.preventDefault();
        var $this, _href;
        $this = $(this);
        _href = $this.attr('href');
        if (_href.indexOf('#') > -1) {
            _href = _href.slice(_href.indexOf('#') + 1, _href.length);
            if ($(".tab-cont#" + _href).length) {
                $('.tab-btn[href="' + _href + '"]').click();
                return;
            }
        }
        document.location = $this.attr('href');
    })

    $('.tab-btn').click(function(e) {
        e.preventDefault();
        var $this = $(this);
        var _tab = $(this).attr('href');
        document.location.hash = _tab;
        $this.siblings('.tab-btn').removeClass('active');
        $this.addClass('active');
        $('.nav-main a').removeClass('current-page');
        $('.nav-main a[href="' + _tab + '"]').addClass('current-page');
        $(".tab-cont#" + _tab).closest('.tabs').find('.tab-cont').removeClass('active');
        $(".tab-cont#" + _tab).addClass('active');
        $('html,body').animate({
            scrollTop: $this.offset().top - 30
        }, 200);
    });

    if ($('.gallery-cont').length) {
        $('.gallery-cont').magnificPopup({
            delegate: 'a',
            type: 'image',
            closeOnContentClick: false,
            closeBtnInside: false,
            mainClass: 'mfp-with-zoom mfp-img-mobile',
            image: {
                verticalFit: true,
            },
            gallery: {
                enabled: true,
                preload: [0, 2],
                tCounter: ''
            },
            zoom: {
                enabled: true,
                duration: 300,
                opener: function(element) {
                    return element.find('img');
                }
            }
        });
    }

    if ($('.rmenu-cont').length) {
        $('.rmenu-cont .rmenu-image').magnificPopup({
            delegate: 'a',
            type: 'image',
            closeOnContentClick: false,
            closeBtnInside: false,
            mainClass: 'mfp-with-zoom mfp-img-mobile',
            image: {
                verticalFit: true,
            },
            gallery: {
                enabled: true,
                preload: [0, 2],
                tCounter: ''
            },
            zoom: {
                enabled: true,
                duration: 300,
                opener: function(element) {
                    return element.find('img');
                }
            }
        });
    }

    $('.category-btn, .subcategory-btn').click(function(e) {
        e.preventDefault();
        if (!$('.sidebar-disabled').length) {
            var $this = $(this);
            if ($('.main-container').hasClass('open')) {
                $this.closest('.category-section').removeClass('active');
                $this.closest('.main-container').removeClass('open');
                $('body,html').animate({
                    scrollTop: $('.main-container').offset().top - 50
                }, 200);
                setTimeout(function() {
                    $('.center-side').stop().fadeIn('200');
                }, 300);
            } else {
                $('.center-side').stop().fadeOut('300', function() {
                    $this.closest('.category-section').addClass('active');
                    $this.closest('.main-container').addClass('open');

                    var interval = setInterval(function() {
                        window.requestAnimationFrame(function() {
                            $('body,html').scrollTop($this.offset().top);
                        })
                    }, 10);
                    setTimeout(function() {
                        clearInterval(interval);
                    }, 310);
                });
            }
        }
    });

if ($('.left-side').length) {
    var $mobileScroll = $('.mobile-sidebar');
    $(window).scroll(function() {
        if ($(window).scrollTop() < $mobileScroll.offset().top && $mobileScroll.hasClass('menu-fixed')) {
            $mobileScroll.removeClass('menu-fixed');
        } else if ($(window).scrollTop() >= $mobileScroll.offset().top && !$mobileScroll.hasClass('menu-fixed')) {
            $mobileScroll.addClass('menu-fixed');
        }
    }).scroll();
}


if ($('.home-section').length) {
    var winScroll, $section = $('.home-section');
    $(window).scroll(function() {
        winScroll = $(window).scrollTop();
        if (winScroll + 0.75 * $(window).height() > $section.offset().top && !$section.hasClass('ready')) {
            $section.addClass('ready');
        }
    }).scroll();
}

if ($('.partners-slider').length) {
    $('.partners-slider').slick({
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 6,
        arrows: false,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        }, {
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        }, {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }]
    });
}

if ($('.slider-right').length) {
    $('.slider-right').slick({
        infinite: true,
        slidesToShow: 1,
        arrows: false,
        speed: 300,
        autoplay: true,
        autoplaySpeed: 6000
                // ,
                // fade: true,
                // cssEase: 'linear'
            });
}

if ($('.customSelect').length) {
    $('.customSelect').customSelect();
}

$('body').delegate('.accordion-header', 'click', function(e) {
    e.preventDefault();
    if ($(this).closest('.modals').length) {
        return;
    }
    if (!$(e.target).closest('input').length) {
        $(this).closest('.accordion:not(".disabled")').toggleClass('open');
        $(this).closest('.accordion:not(".disabled")').siblings().removeClass('open');
    }
});

if ($('.section-top').length) {
    setTimeout(function() {
        $('.section-top').addClass('ready');
    }, 500);

    var winWidth = $(window).width(),
    xMouse, xPerc,
    xPos, $skews;

    $skews = $('.section-top').find('.skews-cont');
    $(window).resize(function() {
        winWidth = $(window).width();
    });
}

if ($('.optionsBox').length) {
    $('body').delegate('.optionsBox .options_lvl_1 > li', 'click', function(e) {
        e.preventDefault();
        $(this).toggleClass('active');
    });
}

if ($('.rwd-img').length) {
    RwdImage();
    $(window).resize(function() {
        RwdImage();
    });
}

$('#btnRestaurants').click(function(e) {
    e.preventDefault();
    if ($('.desktop-sidebar').length) {
        if ($('.category-section').hasClass('active')) {
            $('body,html').animate({
                scrollTop: $('.desktop-sidebar').offset().top
            }, 300);
        } else {
            $('.category-btn').eq(0).click();
        }
    } else {
        document.location = $(this).attr('href');
    }
});

$('.map').each(function() {
    var $this = $(this),
    map = new GMaps({
        div: '#' + $this.attr('id'),
        lat: $this.attr('data-lat'),
        lng: $this.attr('data-lng'),
        fitZoom: true,
        zoom: 14,
        scrollwheel: false
    });

    map.addMarker({
        lat: $this.attr('data-lat'),
        lng: $this.attr('data-lng'),
        title: $this.attr('title')
    });
    map.setCenter($this.attr('data-lat'), $this.attr('data-lng'));
    $(window).resize(function() {
        map.setCenter($this.attr('data-lat'), $this.attr('data-lng'));
    });
});

$('[data-toggle="tooltip"]').tooltip();

$(document).on('hidden.bs.modal', '.modal', function() {
    $('.modal:visible').length && $(document.body).addClass('modal-open');
});

});


function RwdImage() {
    $('.rwd-img').each(function() {
        if ($('#rwd-checker').width() > 3 && $(this).attr('src') != $(this).attr('data-mobile')) {
            $(this).attr('src', $(this).attr('data-mobile'));
        } else if ($('#rwd-checker').width() <= 3 && $(this).attr('src') != $(this).attr('data-desktop')) {
            $(this).attr('src', $(this).attr('data-desktop'));
        }
    });
}


function ShowLoader() {
    if (!$('#save-loader').length) {
        var _template = '<section id="save-loader"><div class="d-tab">';
        _template += '<div class="d-cell"><div class="browser-screen-loading-content">';
        _template += '<div class="loading-dots dark-gray">';
        _template += '<i></i><i></i><i></i><i></i></div></div>';
        _template += '</div></div></section>';

        var _temp = $(_template).appendTo('body');
        _temp.hide();
        _temp.fadeIn('200');
    }
}

function HideLoader() {
    if ($('#save-loader').length) {
        $('#save-loader').fadeOut('50', function() {
            $('#save-loader').remove();
        });
    }
}

function ShowModal(title, text, buttonText, buttonClass, textCenter) {
    var _popup = Handlebars.compile($('#universalPopup').html());
    var options = {};
    options.title = title;
    options.text = text;
    options.buttonText = buttonText;
    options.buttonClass = buttonClass;
    $('body').append(_popup(options));
    $('#modal-universal').modal('show');
    $('#modal-universal').on('hidden.bs.modal', function(e) {
        $('#modal-universal').remove();
    });
    var $_modalBody = $('#modal-universal .modal-body');
    if (typeof textCenter !== 'undefined' && textCenter) {
        $_modalBody.addClass('text-center');
    }
}

function setCookie(cname, cvalue, exmins) {
    var d = new Date();
    d.setTime(d.getTime() + (exmins * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return "";
}

(function($) {
    $.fn.removeOrphans = function() {
        if ($(this).length > 0) {
            var $html = $(this).html();
            $html = $html.replace(/(\s)([\S])[\s]+/g, "$1$2&nbsp;");
            $(this).empty().html($html);
        }
    }
})(jQuery);
